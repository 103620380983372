// JsFromRoutes CacheKey 246a66e7a1576f30f3fe8ef5059799ef
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/javascriptFile'),
  loggedUser: /* #__PURE__ */ definePathHelper('get', '/javascript/data/logged-user/:fields'),
  loggedOrganisation: /* #__PURE__ */ definePathHelper('get', '/javascript/data/logged-user/organisation/:fields'),
  loggedParticipant: /* #__PURE__ */ definePathHelper('get', '/javascript/data/logged-participant/:document_id/:fields'),
  template: /* #__PURE__ */ definePathHelper('get', '/javascript/data/template/:id/:fields'),
  templateVersion: /* #__PURE__ */ definePathHelper('get', '/javascript/data/template-version/:id/:fields'),
  document: /* #__PURE__ */ definePathHelper('get', '/javascript/data/document/:id/:fields'),
  draft: /* #__PURE__ */ definePathHelper('get', '/javascript/data/draft/:id/:fields'),
  deal: /* #__PURE__ */ definePathHelper('get', '/javascript/data/deal/:id/:fields'),
  dealGet: /* #__PURE__ */ definePathHelper('get', '/javascript/data/deal/:id/:fields'),
  customClauseVariant: /* #__PURE__ */ definePathHelper('get', '/javascript/data/custom-clause-variant/:id/:fields'),
  customClauses: /* #__PURE__ */ definePathHelper('get', '/javascript/data/custom-clause/:id/:fields'),
  localization: /* #__PURE__ */ definePathHelper('get', '/javascript/data/localization/:locale/:fields'),
  dealVersion: /* #__PURE__ */ definePathHelper('get', '/javascript/data/deal-version/:id/:fields'),
  documentView: /* #__PURE__ */ definePathHelper('get', '/javascript/data/views/document/:document_id'),
  templateView: /* #__PURE__ */ definePathHelper('get', '/javascript/data/views/template/:template_id'),
}
